<template>
  <div class="-mt-2 hidden h-[52px] w-full lg:block" :class="mnyams && 'mt-0'">
    <!-- v-if="!isCatalog" -->
    <transition name="search-bg">
      <!-- && !isCatalog -->
      <div
        v-if="!isCatalog && desktopDialogShown"
        class="fixed inset-0 z-20 bg-gray-800/30 blur-lg transition-opacity duration-300"
        :class="(craftia || award) && 'hidden'"
      >
        &nbsp;
      </div>
    </transition>
    <div
      ref="searchPanelRef"
      class="relative -mt-1 hidden w-full rounded-t-lg bg-white p-3 lg:block"
      :class="[
        { 'shadow-medium z-20 ': !isCatalog && desktopDialogShown },
        { 'mt-2 !rounded-full !px-2 !py-1': award || fiory },
        { '!mt-0 !rounded-full !p-0': mnyams },
        { '!mt-0 !bg-gray-50 !p-2': isb },
        { '!mt-0 p-1': craftia },
        { '!mt-0 !rounded-full !bg-transparent !p-1': b4p },
      ]"
    >
      <!-- !h-[52px] !min-h-[52px]  -->
      <div
        class="input input_lg relative z-20 flex"
        :class="[
          desktopDialogShown && 'ring-primary ring-1',
          { '!min-h-[35px] !px-2': award },
          { '!bg-white': mnyams },
          { '!h-[37px] !min-h-[37px]': isb },
          {
            'placeholder:text-primary flex-row-reverse rounded-full':
              fiory || panda,
          },
          { '!rounded-full !bg-[#282A2C]': b4p },
        ]"
        @click="showDesktopDialog(true)"
      >
        <div
          class="flex items-center"
          :class="[
            award && 'text-primary',
            isb && 'text-primary-500',
            (fiory || panda) && 'text-gray-300',
          ]"
        >
          <Icon name="search-alt" class="h-[22px] w-[22px]" />
        </div>
        <input
          id="appSearchInput"
          v-model="search"
          data-test="header_input_search"
          :placeholder="placeholder"
          aria-label="search input"
          :class="[
            'input input_lg text-general shadow-container placeholder:text-blue-gray-500 pr-4 leading-6 !ring-0 placeholder:text-sm placeholder:font-medium lg:shadow-none',
            award && 'border-primary mr-3 !min-h-[35px] rounded-b-none py-0',
            mnyams && '!bg-white',
            isb && 'placeholder:text-primary-500 !h-[37px] !min-h-[37px] px-2',
            fiory && 'text-primary pl-1 placeholder:text-gray-300',
            b4p &&
              ' !h-[48px] !min-h-[48px] rounded-full !bg-transparent !text-white placeholder:opacity-50',
          ]"
          :style="b4p ? { '-webkit-text-fill-color': '#fff !important' } : {}"
          @input="showDesktopDialog(true)"
          @keydown.enter="gotoSearch(searchEnough)"
          @blur="closeSearch(searchEnough)"
        />
        <Btn
          v-if="!!search"
          class="text-blue-gray-400 hover:text-blue-gray-500"
          @click.stop="delSearch"
        >
          <Icon name="close-round-fill" class="h-[22px] w-[22px]" />
        </Btn>
      </div>
      <transition name="search-content">
        <div
          v-if="!isCatalog && desktopDialogShown"
          class="absolute left-0 top-full w-full"
          :class="{ '!top-6 z-10': award || fiory }"
        >
          <AppHeaderSearchList
            class="rounded-b-lg bg-white"
            :class="[(award || fiory) && 'pt-10', b4p && '!rounded-xl']"
            :search="searchEnough"
            :products="products"
            :is-catalog="isCatalog"
            :loading="pending"
            :show-products="!isCatalog"
            @goto="gotoSearch(search)"
            @close="closeDialog"
          >
            <template #history>
              <AppHeaderSearchHistory
                v-if="phrases.length"
                :phrases="phrases"
                @select="gotoSearch"
                @delete="delPhrase"
              />
            </template>
          </AppHeaderSearchList>
        </div>
      </transition>
    </div>
  </div>
  <div class="grow lg:hidden">
    <div v-if="panda" @click="() => (mobileDialogShown = true)">
      <div
        class="search-border h-10.5 flex w-full items-center gap-3 rounded-full px-3 text-xs text-gray-300"
      >
        <Icon name="search-alt" class="h-[18px] w-[18px]" /> Поиск по каталогу
      </div>
    </div>
    <Btn
      v-else
      secondary
      class="bg-btn-secondary text-primary app_btn_secondary lg:text-blue-gray-500 h-[52px] w-[52px] lg:hidden"
      :class="[
        kranch && 'grid h-full w-full grid-cols-[auto_1fr_auto]',
        award && '!bg-primary',
        b4p && '!h-[42px] !w-[42px] bg-white text-black',
        fiory &&
          '!bg-primary md:!text-primary !w-[32px] px-1 text-white sm:!w-[52px] md:!bg-white',
      ]"
      @click="() => (mobileDialogShown = true)"
    >
      <Icon
        name="search-alt"
        class="h-[22px] w-[22px]"
        :class="[award && 'text-white', fiory && '!h-[24px] !w-[24px]']"
      />
      <span v-if="kranch" class="ml-2 overflow-hidden text-ellipsis text-left">
        {{ route.query?.search || placeholder || 'Поиск' }}
      </span>
    </Btn>
    <Dialog v-model="mobileDialogShown" fullscreen-on-mobile>
      <AppHeaderSearchTile
        v-model:search="search"
        class="px-4"
        :products="products"
        :phrases="phrases"
        :loading="pending"
        @goto="gotoSearch(search)"
        @clear="delSearch"
        @close="closeDialog"
      >
        <template #history>
          <AppHeaderSearchHistory
            v-if="phrases.length"
            :phrases="phrases"
            @select="gotoSearch"
            @delete="delPhrase"
          />
        </template>
      </AppHeaderSearchTile>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { omit } from 'lodash-es'
import type { Phrase } from '@/openapi_fetch'

withDefaults(
  defineProps<{
    kranch?: Boolean
    craftia?: Boolean
    mnyams?: Boolean
    fiory?: Boolean
    award?: Boolean
    isb?: Boolean
    panda?: Boolean
    b4p?: Boolean
    modelValue: Boolean
    placeholder?: string
  }>(),
  {
    placeholder: 'Искать товары',
  },
)
const emit = defineEmits(['update:modelValue', 'update:mobileDialogShown'])
const { AnalyticsApi } = useOpenApi()
const MIN_SEARCH_LENGTH = 3

const route = useRoute()

const isCatalog = computed(() =>
  String(route.name).split('-').includes('catalog'),
)

const desktopDialogShown = ref(false)
const showDesktopDialog = (val: boolean) => {
  desktopDialogShown.value = val
}
const searchPanelRef = ref<HTMLInputElement | null>(null)
onClickOutside(searchPanelRef, () => {
  showDesktopDialog(false)
})

const mobileDialogShown = ref(false)
const showMobileDialog = (val: boolean) => {
  mobileDialogShown.value = val
}
watch(mobileDialogShown, () => {
  emit('update:mobileDialogShown')
})

const search = ref('')
const searchEnough = computed(() =>
  search.value.length >= MIN_SEARCH_LENGTH ? search.value : '',
)
const searchEnoughDebounced = refDebounced(searchEnough, 1000)
watchEffect(() => {
  const searchRouteParams = (route.query?.search || '') as string
  search.value =
    searchRouteParams.length >= MIN_SEARCH_LENGTH ? searchRouteParams : ''
})

const { CatalogApi } = useOpenApi()
const {
  data: productsSuggestion,
  pending: pendingProductsSuggestion,
  refresh: refreshProductsSuggestion,
} = await useLazyAsyncData(
  '/catalog/products/search/',
  () =>
    CatalogApi.catalogProductsList({
      ...(searchEnough.value && { search: searchEnoughDebounced.value }),
    }),
  { immediate: false },
)
const pending = computed(
  () => !isCatalog.value && pendingProductsSuggestion.value,
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
watch(
  [isCatalog, searchEnoughDebounced],
  (
    [isCatalogVal, searchEnoughDebouncedVal],
    [_isCatalogOld, searchEnoughDebouncedOld],
  ) => {
    if (
      searchEnoughDebouncedVal === searchEnoughDebouncedOld ||
      process.server
    ) {
      return
    }
    if (isCatalogVal) {
      navigateToSearch(searchEnoughDebouncedVal)
    } else {
      refreshProductsSuggestion()
    }
  },
)
const products = computed(() => {
  return searchEnough.value ? productsSuggestion.value?.results : []
})

function delSearch() {
  search.value = ''
}

function closeDialog() {
  showDesktopDialog(false)
  showMobileDialog(false)
  emit('update:modelValue', false)
}

function navigateToSearch(val: string) {
  const isFromCatalog = /^catalog-.*characteristic$/.test(route.name as string)
  const query = val
    ? { ...route.query, search: val, page: undefined }
    : omit(route.query, ['search', 'page'])
  navigateTo({
    name: isFromCatalog ? route.name ?? undefined : 'catalog-characteristic',
    params: isFromCatalog ? route.params : {},
    query,
  })
}

function gotoSearch(val: string) {
  closeDialog()
  if (!val) {
    return
  }
  createPhrase(val)
  navigateToSearch(val)
}

function closeSearch(val: string) {
  if (val) {
    createPhrase(val)
  } else {
    delSearch()
  }
}
/*
 * phrases
 */
const phrases = ref<Phrase[]>([])

const debunceFetchPhrases = useDebounceFn(fetchPhrases, 1000)
watch(
  search,
  async () => {
    if (process.server) {
      return
    }
    await debunceFetchPhrases()
  },
  { immediate: true },
)
async function fetchPhrases() {
  phrases.value = await AnalyticsApi.analyticsPhrasesList({
    ...(searchEnough.value && { search: searchEnough.value }),
  })
}

async function delPhrase(phrase_id: number) {
  phrases.value = phrases.value.filter((item: any) => item.id !== phrase_id)
  await AnalyticsApi.analyticsPhrasesDestroy({ id: phrase_id })
  await fetchPhrases()
}

async function createPhrase(phrase: string) {
  if (phrase) {
    await AnalyticsApi.analyticsPhrasesCreate({
      phraseRequest: {
        phrase,
      },
    })
  }
}
</script>

<style>
.search-bg-enter-active,
.search-bg-leave-active {
  @apply transition-opacity duration-100;
}

.search-bg-enter-from,
.search-bg-leave-to {
  @apply opacity-0;
}

.search-content-enter-active,
.search-content-leave-active {
  @apply top-full transition-[top,opacity] duration-100;
}

.search-content-enter-from,
.search-content-leave-to {
  @apply top-[50vh] opacity-0;
}
</style>
