/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaymentOrderIDRequest,
  RedirectUrl,
} from '../models';
import {
    PaymentOrderIDRequestFromJSON,
    PaymentOrderIDRequestToJSON,
    RedirectUrlFromJSON,
    RedirectUrlToJSON,
} from '../models';

export interface PaymentsCheckCreateRequest {
    paymentOrderIDRequest: PaymentOrderIDRequest;
}

export interface PaymentsRegisterCreateRequest {
    paymentOrderIDRequest: PaymentOrderIDRequest;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * Получить информацию по статусу заказа в платежной системе. https://securepayments.sberbank.ru/wiki/doku.php/integration:api:rest:requests:getorderstatusextended
     * Запрос на получение статуса заказа в платежном шлюзе.
     */
    async paymentsCheckCreateRaw(requestParameters: PaymentsCheckCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.paymentOrderIDRequest === null || requestParameters.paymentOrderIDRequest === undefined) {
            throw new runtime.RequiredError('paymentOrderIDRequest','Required parameter requestParameters.paymentOrderIDRequest was null or undefined when calling paymentsCheckCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/payments/check/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentOrderIDRequestToJSON(requestParameters.paymentOrderIDRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Получить информацию по статусу заказа в платежной системе. https://securepayments.sberbank.ru/wiki/doku.php/integration:api:rest:requests:getorderstatusextended
     * Запрос на получение статуса заказа в платежном шлюзе.
     */
    async paymentsCheckCreate(requestParameters: PaymentsCheckCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.paymentsCheckCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Регистрация в сбербанке и создание в БД предоплаченного заказа, с возвратом ссылки для оплаты.
     * Запрос на регистрацию заказа с получением ссылки на платеж с холдированием суммы.
     */
    async paymentsRegisterCreateRaw(requestParameters: PaymentsRegisterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RedirectUrl>> {
        if (requestParameters.paymentOrderIDRequest === null || requestParameters.paymentOrderIDRequest === undefined) {
            throw new runtime.RequiredError('paymentOrderIDRequest','Required parameter requestParameters.paymentOrderIDRequest was null or undefined when calling paymentsRegisterCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/payments/register/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentOrderIDRequestToJSON(requestParameters.paymentOrderIDRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedirectUrlFromJSON(jsonValue));
    }

    /**
     * Регистрация в сбербанке и создание в БД предоплаченного заказа, с возвратом ссылки для оплаты.
     * Запрос на регистрацию заказа с получением ссылки на платеж с холдированием суммы.
     */
    async paymentsRegisterCreate(requestParameters: PaymentsRegisterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RedirectUrl> {
          try{
        const response = await this.paymentsRegisterCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
