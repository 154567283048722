/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CategoryDeliveryType } from './CategoryDeliveryType';
import {
    CategoryDeliveryTypeFromJSON,
    CategoryDeliveryTypeFromJSONTyped,
    CategoryDeliveryTypeToJSON,
} from './CategoryDeliveryType';
import type { CdekTrakingInfo } from './CdekTrakingInfo';
import {
    CdekTrakingInfoFromJSON,
    CdekTrakingInfoFromJSONTyped,
    CdekTrakingInfoToJSON,
} from './CdekTrakingInfo';
import type { DeliverPoints } from './DeliverPoints';
import {
    DeliverPointsFromJSON,
    DeliverPointsFromJSONTyped,
    DeliverPointsToJSON,
} from './DeliverPoints';
import type { DeliveryAddress } from './DeliveryAddress';
import {
    DeliveryAddressFromJSON,
    DeliveryAddressFromJSONTyped,
    DeliveryAddressToJSON,
} from './DeliveryAddress';
import type { DeliveryType } from './DeliveryType';
import {
    DeliveryTypeFromJSON,
    DeliveryTypeFromJSONTyped,
    DeliveryTypeToJSON,
} from './DeliveryType';
import type { DepositRequestStatusEnum } from './DepositRequestStatusEnum';
import {
    DepositRequestStatusEnumFromJSON,
    DepositRequestStatusEnumFromJSONTyped,
    DepositRequestStatusEnumToJSON,
} from './DepositRequestStatusEnum';
import type { Filial } from './Filial';
import {
    FilialFromJSON,
    FilialFromJSONTyped,
    FilialToJSON,
} from './Filial';
import type { OrderItem } from './OrderItem';
import {
    OrderItemFromJSON,
    OrderItemFromJSONTyped,
    OrderItemToJSON,
} from './OrderItem';
import type { OrderPeriod } from './OrderPeriod';
import {
    OrderPeriodFromJSON,
    OrderPeriodFromJSONTyped,
    OrderPeriodToJSON,
} from './OrderPeriod';
import type { OrderStatus } from './OrderStatus';
import {
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';
import type { PromoCode } from './PromoCode';
import {
    PromoCodeFromJSON,
    PromoCodeFromJSONTyped,
    PromoCodeToJSON,
} from './PromoCode';
import type { RefOrders } from './RefOrders';
import {
    RefOrdersFromJSON,
    RefOrdersFromJSONTyped,
    RefOrdersToJSON,
} from './RefOrders';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    integrationId?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    readonly user: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    receiverFullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    sessionKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    phone?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof Order
     */
    readonly status: OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    comment?: string | null;
    /**
     * 
     * @type {DeliveryAddress}
     * @memberof Order
     */
    address?: DeliveryAddress;
    /**
     * 
     * @type {Filial}
     * @memberof Order
     */
    readonly filial: Filial;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    deliveryDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    deliveryRegularity?: number | null;
    /**
     * 
     * @type {PaymentType}
     * @memberof Order
     */
    readonly paymentMethods: PaymentType;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    timeFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    timeTill?: string | null;
    /**
     * Вес заказа в килограммах
     * @type {number}
     * @memberof Order
     */
    readonly totalWeight: number;
    /**
     * 
     * @type {PromoCode}
     * @memberof Order
     */
    readonly promoCode: PromoCode;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    confirmedDate?: Date | null;
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof Order
     */
    readonly orderItems: Array<OrderItem>;
    /**
     * 
     * @type {any}
     * @memberof Order
     */
    readonly type: any | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    readonly formUrl: string;
    /**
     * Количество товаров в заказе
     * @type {number}
     * @memberof Order
     */
    readonly totalOrderCountProduct: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    deliveryPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    isPaid?: boolean;
    /**
     * 
     * @type {Array<PaymentType>}
     * @memberof Order
     */
    readonly paymentTypes: Array<PaymentType>;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    readonly dateOfPayment: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    readonly isValidFilial: boolean;
    /**
     * 
     * @type {Array<RefOrders>}
     * @memberof Order
     */
    readonly refOrders: Array<RefOrders>;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    readonly parentOrders: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    readonly totalOrderPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    bonusUsed?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    isBonusAccrual?: boolean;
    /**
     * Внутренняя категория доставки для сайта
     * @type {number}
     * @memberof Order
     */
    categoryDeliveryType?: number | null;
    /**
     * 
     * @type {Array<CategoryDeliveryType>}
     * @memberof Order
     */
    readonly orderCategoryDeliveryType: Array<CategoryDeliveryType>;
    /**
     * 
     * @type {DeliveryType}
     * @memberof Order
     */
    readonly deliveryType: DeliveryType;
    /**
     * 
     * @type {DeliverPoints}
     * @memberof Order
     */
    readonly pickupAddress: DeliverPoints;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    cdekCode?: string | null;
    /**
     * 
     * @type {CdekTrakingInfo}
     * @memberof Order
     */
    readonly cdekTrackingInfo: CdekTrakingInfo;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    absoluteDiscount?: number;
    /**
     * 
     * @type {OrderPeriod}
     * @memberof Order
     */
    readonly period: OrderPeriod;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    isChanged?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    totalOrderPercentDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    readonly totalOrderDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    readonly totalOrderUserPrice: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    readonly deliveryPriceDefined: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    isOtherRecipient?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    isCheckout?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    checkoutDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    readonly bonusAccrual: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    readonly bonusWriteOff: number;
    /**
     * 
     * @type {DepositRequestStatusEnum}
     * @memberof Order
     */
    readonly depositRequestStatus: DepositRequestStatusEnum | null;
}

/**
 * Check if a given object implements the Order interface.
 */
export function instanceOfOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "filial" in value;
    isInstance = isInstance && "paymentMethods" in value;
    isInstance = isInstance && "totalWeight" in value;
    isInstance = isInstance && "promoCode" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "orderItems" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "formUrl" in value;
    isInstance = isInstance && "totalOrderCountProduct" in value;
    isInstance = isInstance && "paymentTypes" in value;
    isInstance = isInstance && "dateOfPayment" in value;
    isInstance = isInstance && "isValidFilial" in value;
    isInstance = isInstance && "refOrders" in value;
    isInstance = isInstance && "parentOrders" in value;
    isInstance = isInstance && "totalOrderPrice" in value;
    isInstance = isInstance && "orderCategoryDeliveryType" in value;
    isInstance = isInstance && "deliveryType" in value;
    isInstance = isInstance && "pickupAddress" in value;
    isInstance = isInstance && "cdekTrackingInfo" in value;
    isInstance = isInstance && "period" in value;
    isInstance = isInstance && "totalOrderDiscount" in value;
    isInstance = isInstance && "totalOrderUserPrice" in value;
    isInstance = isInstance && "deliveryPriceDefined" in value;
    isInstance = isInstance && "bonusAccrual" in value;
    isInstance = isInstance && "bonusWriteOff" in value;
    isInstance = isInstance && "depositRequestStatus" in value;

    return isInstance;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'user': json['user'],
        'receiverFullName': !exists(json, 'receiver_full_name') ? undefined : json['receiver_full_name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'sessionKey': !exists(json, 'session_key') ? undefined : json['session_key'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'status': OrderStatusFromJSON(json['status']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'address': !exists(json, 'address') ? undefined : DeliveryAddressFromJSON(json['address']),
        'filial': FilialFromJSON(json['filial']),
        'deliveryDate': !exists(json, 'delivery_date') ? undefined : (json['delivery_date'] === null ? null : new Date(json['delivery_date'])),
        'deliveryRegularity': !exists(json, 'delivery_regularity') ? undefined : json['delivery_regularity'],
        'paymentMethods': PaymentTypeFromJSON(json['payment_methods']),
        'timeFrom': !exists(json, 'time_from') ? undefined : json['time_from'],
        'timeTill': !exists(json, 'time_till') ? undefined : json['time_till'],
        'totalWeight': json['total_weight'],
        'promoCode': PromoCodeFromJSON(json['promo_code']),
        'created': (new Date(json['created'])),
        'confirmedDate': !exists(json, 'confirmed_date') ? undefined : (json['confirmed_date'] === null ? null : new Date(json['confirmed_date'])),
        'orderItems': ((json['order_items'] as Array<any>).map(OrderItemFromJSON)),
        'type': json['type'],
        'formUrl': json['form_url'],
        'totalOrderCountProduct': json['total_order_count_product'],
        'deliveryPrice': !exists(json, 'delivery_price') ? undefined : json['delivery_price'],
        'isPaid': !exists(json, 'is_paid') ? undefined : json['is_paid'],
        'paymentTypes': ((json['payment_types'] as Array<any>).map(PaymentTypeFromJSON)),
        'dateOfPayment': (new Date(json['date_of_payment'])),
        'isValidFilial': json['is_valid_filial'],
        'refOrders': ((json['ref_orders'] as Array<any>).map(RefOrdersFromJSON)),
        'parentOrders': json['parent_orders'],
        'totalOrderPrice': json['total_order_price'],
        'bonusUsed': !exists(json, 'bonus_used') ? undefined : json['bonus_used'],
        'isBonusAccrual': !exists(json, 'is_bonus_accrual') ? undefined : json['is_bonus_accrual'],
        'categoryDeliveryType': !exists(json, 'category_delivery_type') ? undefined : json['category_delivery_type'],
        'orderCategoryDeliveryType': ((json['order_category_delivery_type'] as Array<any>).map(CategoryDeliveryTypeFromJSON)),
        'deliveryType': DeliveryTypeFromJSON(json['delivery_type']),
        'pickupAddress': DeliverPointsFromJSON(json['pickup_address']),
        'cdekCode': !exists(json, 'cdek_code') ? undefined : json['cdek_code'],
        'cdekTrackingInfo': CdekTrakingInfoFromJSON(json['cdek_tracking_info']),
        'absoluteDiscount': !exists(json, 'absolute_discount') ? undefined : json['absolute_discount'],
        'period': OrderPeriodFromJSON(json['period']),
        'isChanged': !exists(json, 'is_changed') ? undefined : json['is_changed'],
        'totalOrderPercentDiscount': !exists(json, 'total_order_percent_discount') ? undefined : json['total_order_percent_discount'],
        'totalOrderDiscount': json['total_order_discount'],
        'totalOrderUserPrice': json['total_order_user_price'],
        'deliveryPriceDefined': json['delivery_price_defined'],
        'isOtherRecipient': !exists(json, 'is_other_recipient') ? undefined : json['is_other_recipient'],
        'isCheckout': !exists(json, 'is_checkout') ? undefined : json['is_checkout'],
        'checkoutDate': !exists(json, 'checkout_date') ? undefined : (json['checkout_date'] === null ? null : new Date(json['checkout_date'])),
        'bonusAccrual': json['bonus_accrual'],
        'bonusWriteOff': json['bonus_write_off'],
        'depositRequestStatus': DepositRequestStatusEnumFromJSON(json['deposit_request_status']),
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integration_id': value.integrationId,
        'title': value.title,
        'receiver_full_name': value.receiverFullName,
        'full_name': value.fullName,
        'session_key': value.sessionKey,
        'email': value.email,
        'phone': value.phone,
        'comment': value.comment,
        'address': DeliveryAddressToJSON(value.address),
        'delivery_date': value.deliveryDate === undefined ? undefined : (value.deliveryDate === null ? null : value.deliveryDate.toISOString().substr(0,10)),
        'delivery_regularity': value.deliveryRegularity,
        'time_from': value.timeFrom,
        'time_till': value.timeTill,
        'confirmed_date': value.confirmedDate === undefined ? undefined : (value.confirmedDate === null ? null : value.confirmedDate.toISOString()),
        'delivery_price': value.deliveryPrice,
        'is_paid': value.isPaid,
        'bonus_used': value.bonusUsed,
        'is_bonus_accrual': value.isBonusAccrual,
        'category_delivery_type': value.categoryDeliveryType,
        'cdek_code': value.cdekCode,
        'absolute_discount': value.absoluteDiscount,
        'is_changed': value.isChanged,
        'total_order_percent_discount': value.totalOrderPercentDiscount,
        'is_other_recipient': value.isOtherRecipient,
        'is_checkout': value.isCheckout,
        'checkout_date': value.checkoutDate === undefined ? undefined : (value.checkoutDate === null ? null : value.checkoutDate.toISOString()),
    };
}

