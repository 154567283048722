/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DepositRequestStatusEnum = {
    New: 'new',
    LinkReceived: 'link_received',
    Hold: 'hold',
    Confirmed: 'confirmed',
    Canceled: 'canceled',
    Refunded: 'refunded',
    Processing: 'processing',
    Timeout: 'timeout',
    Pending: 'pending'
} as const;
export type DepositRequestStatusEnum = typeof DepositRequestStatusEnum[keyof typeof DepositRequestStatusEnum];


export function DepositRequestStatusEnumFromJSON(json: any): DepositRequestStatusEnum {
    return DepositRequestStatusEnumFromJSONTyped(json, false);
}

export function DepositRequestStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepositRequestStatusEnum {
    return json as DepositRequestStatusEnum;
}

export function DepositRequestStatusEnumToJSON(value?: DepositRequestStatusEnum | null): any {
    return value as any;
}

