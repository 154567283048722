import { defineNuxtRouteMiddleware, useAuth, navigateTo } from '#imports'

export default defineNuxtRouteMiddleware(async (to) => {
  const { loggedIn, getRedirectURL } = useAuth()

  if (!loggedIn.value) {
    return navigateTo(
      await getRedirectURL({
        returnURLParams: to,
      }),
      { external: true },
    )
  }
})
