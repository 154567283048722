/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `LESS_MINIMUM_WEIGHT` - Min
 * * `GREATER_MAXIMUM_WEIGHT` - Max
 * * `NONE` - None
 * @export
 */
export const BlockedTypeEnum = {
    LessMinimumWeight: 'LESS_MINIMUM_WEIGHT',
    GreaterMaximumWeight: 'GREATER_MAXIMUM_WEIGHT',
    None: 'NONE'
} as const;
export type BlockedTypeEnum = typeof BlockedTypeEnum[keyof typeof BlockedTypeEnum];


export function BlockedTypeEnumFromJSON(json: any): BlockedTypeEnum {
    return BlockedTypeEnumFromJSONTyped(json, false);
}

export function BlockedTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockedTypeEnum {
    return json as BlockedTypeEnum;
}

export function BlockedTypeEnumToJSON(value?: BlockedTypeEnum | null): any {
    return value as any;
}

