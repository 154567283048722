/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseCharacteristic } from './BaseCharacteristic';
import {
    BaseCharacteristicFromJSON,
    BaseCharacteristicFromJSONTyped,
    BaseCharacteristicToJSON,
} from './BaseCharacteristic';
import type { DiscountProduct } from './DiscountProduct';
import {
    DiscountProductFromJSON,
    DiscountProductFromJSONTyped,
    DiscountProductToJSON,
} from './DiscountProduct';
import type { FilialProdict } from './FilialProdict';
import {
    FilialProdictFromJSON,
    FilialProdictFromJSONTyped,
    FilialProdictToJSON,
} from './FilialProdict';
import type { PetType } from './PetType';
import {
    PetTypeFromJSON,
    PetTypeFromJSONTyped,
    PetTypeToJSON,
} from './PetType';
import type { ProductImage } from './ProductImage';
import {
    ProductImageFromJSON,
    ProductImageFromJSONTyped,
    ProductImageToJSON,
} from './ProductImage';
import type { ProductType } from './ProductType';
import {
    ProductTypeFromJSON,
    ProductTypeFromJSONTyped,
    ProductTypeToJSON,
} from './ProductType';
import type { Review } from './Review';
import {
    ReviewFromJSON,
    ReviewFromJSONTyped,
    ReviewToJSON,
} from './Review';
import type { SimpleProductBanner } from './SimpleProductBanner';
import {
    SimpleProductBannerFromJSON,
    SimpleProductBannerFromJSONTyped,
    SimpleProductBannerToJSON,
} from './SimpleProductBanner';
import type { TradeMark } from './TradeMark';
import {
    TradeMarkFromJSON,
    TradeMarkFromJSONTyped,
    TradeMarkToJSON,
} from './TradeMark';

/**
 * Сериализатор структуры данных продукта в Json формат. Помимо скалярных
 * значений, включает в результат список похожих продуктов, список
 * дополняющих продуктов и список изображений продукта
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    readonly id: number;
    /**
     * 
     * @type {ProductType}
     * @memberof Product
     */
    readonly productType: ProductType;
    /**
     * 
     * @type {TradeMark}
     * @memberof Product
     */
    readonly trademark: TradeMark;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    rating: number;
    /**
     * 
     * @type {Array<BaseCharacteristic>}
     * @memberof Product
     */
    characteristics: Array<BaseCharacteristic>;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    readonly price: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    readonly quantity: number;
    /**
     * 
     * @type {DiscountProduct}
     * @memberof Product
     */
    readonly discount: DiscountProduct;
    /**
     * 
     * @type {FilialProdict}
     * @memberof Product
     */
    readonly filial: FilialProdict;
    /**
     * 
     * @type {Array<ProductImage>}
     * @memberof Product
     */
    productImages: Array<ProductImage>;
    /**
     * 
     * @type {Array<Review>}
     * @memberof Product
     */
    readonly reviews: Array<Review>;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    readonly reviewQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    readonly bonusAccrual: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    readonly coverPic: string;
    /**
     * 
     * @type {Array<PetType>}
     * @memberof Product
     */
    readonly petTypeCharacteristics: Array<PetType>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Product
     */
    readonly complementaryProducts: Array<number>;
    /**
     * 
     * @type {SimpleProductBanner}
     * @memberof Product
     */
    readonly productBanner: SimpleProductBanner | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    mskQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    integrationId: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    taxValue?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isHit?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    weight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    volume?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isComparableWeight?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ingredients?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    restrictions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    composition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    dailyConsumption?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    loyaltyPointsToBeCharged?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    storageTemperature?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    popularity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isAvailableForLoyalty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isBaseForLoyalty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isMonoprotein?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isNovelty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isOneRuble?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    readonly lastModified: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    dailyConsumptionPhoto?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    promocodeBlocked?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productLine?: number | null;
}

/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "productType" in value;
    isInstance = isInstance && "trademark" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "rating" in value;
    isInstance = isInstance && "characteristics" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "filial" in value;
    isInstance = isInstance && "productImages" in value;
    isInstance = isInstance && "reviews" in value;
    isInstance = isInstance && "reviewQuantity" in value;
    isInstance = isInstance && "bonusAccrual" in value;
    isInstance = isInstance && "coverPic" in value;
    isInstance = isInstance && "petTypeCharacteristics" in value;
    isInstance = isInstance && "complementaryProducts" in value;
    isInstance = isInstance && "productBanner" in value;
    isInstance = isInstance && "mskQuantity" in value;
    isInstance = isInstance && "integrationId" in value;
    isInstance = isInstance && "lastModified" in value;

    return isInstance;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'productType': ProductTypeFromJSON(json['product_type']),
        'trademark': TradeMarkFromJSON(json['trademark']),
        'name': json['name'],
        'rating': json['rating'],
        'characteristics': ((json['characteristics'] as Array<any>).map(BaseCharacteristicFromJSON)),
        'price': json['price'],
        'quantity': json['quantity'],
        'discount': DiscountProductFromJSON(json['discount']),
        'filial': FilialProdictFromJSON(json['filial']),
        'productImages': ((json['product_images'] as Array<any>).map(ProductImageFromJSON)),
        'reviews': ((json['reviews'] as Array<any>).map(ReviewFromJSON)),
        'reviewQuantity': json['review_quantity'],
        'bonusAccrual': json['bonus_accrual'],
        'coverPic': json['cover_pic'],
        'petTypeCharacteristics': ((json['pet_type_characteristics'] as Array<any>).map(PetTypeFromJSON)),
        'complementaryProducts': json['complementary_products'],
        'productBanner': SimpleProductBannerFromJSON(json['product_banner']),
        'mskQuantity': json['msk_quantity'],
        'integrationId': json['integration_id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'taxValue': !exists(json, 'tax_value') ? undefined : json['tax_value'],
        'isHit': !exists(json, 'is_hit') ? undefined : json['is_hit'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'isComparableWeight': !exists(json, 'is_comparable_weight') ? undefined : json['is_comparable_weight'],
        'ingredients': !exists(json, 'ingredients') ? undefined : json['ingredients'],
        'restrictions': !exists(json, 'restrictions') ? undefined : json['restrictions'],
        'composition': !exists(json, 'composition') ? undefined : json['composition'],
        'dailyConsumption': !exists(json, 'daily_consumption') ? undefined : json['daily_consumption'],
        'loyaltyPointsToBeCharged': !exists(json, 'loyalty_points_to_be_charged') ? undefined : json['loyalty_points_to_be_charged'],
        'storageTemperature': !exists(json, 'storage_temperature') ? undefined : json['storage_temperature'],
        'popularity': !exists(json, 'popularity') ? undefined : json['popularity'],
        'isAvailableForLoyalty': !exists(json, 'is_available_for_loyalty') ? undefined : json['is_available_for_loyalty'],
        'isBaseForLoyalty': !exists(json, 'is_base_for_loyalty') ? undefined : json['is_base_for_loyalty'],
        'isMonoprotein': !exists(json, 'is_monoprotein') ? undefined : json['is_monoprotein'],
        'isNovelty': !exists(json, 'is_novelty') ? undefined : json['is_novelty'],
        'isOneRuble': !exists(json, 'is_one_ruble') ? undefined : json['is_one_ruble'],
        'lastModified': (new Date(json['last_modified'])),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'dailyConsumptionPhoto': !exists(json, 'daily_consumption_photo') ? undefined : json['daily_consumption_photo'],
        'promocodeBlocked': !exists(json, 'promocode_blocked') ? undefined : json['promocode_blocked'],
        'productLine': !exists(json, 'product_line') ? undefined : json['product_line'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'rating': value.rating,
        'characteristics': ((value.characteristics as Array<any>).map(BaseCharacteristicToJSON)),
        'product_images': ((value.productImages as Array<any>).map(ProductImageToJSON)),
        'msk_quantity': value.mskQuantity,
        'integration_id': value.integrationId,
        'code': value.code,
        'description': value.description,
        'tax_value': value.taxValue,
        'is_hit': value.isHit,
        'weight': value.weight,
        'volume': value.volume,
        'is_comparable_weight': value.isComparableWeight,
        'ingredients': value.ingredients,
        'restrictions': value.restrictions,
        'composition': value.composition,
        'daily_consumption': value.dailyConsumption,
        'loyalty_points_to_be_charged': value.loyaltyPointsToBeCharged,
        'storage_temperature': value.storageTemperature,
        'popularity': value.popularity,
        'is_available_for_loyalty': value.isAvailableForLoyalty,
        'is_base_for_loyalty': value.isBaseForLoyalty,
        'is_monoprotein': value.isMonoprotein,
        'is_novelty': value.isNovelty,
        'is_one_ruble': value.isOneRuble,
        'active': value.active,
        'daily_consumption_photo': value.dailyConsumptionPhoto,
        'promocode_blocked': value.promocodeBlocked,
        'product_line': value.productLine,
    };
}

