<template>
  <div
    class="relative flex flex-col justify-between bg-white"
    :class="{ 'rounded-xl border border-gray-100 lg:rounded-2xl': !compare }"
  >
    <div
      class="relative flex items-center justify-center pb-[100%] lg:pb-[78%]"
      :class="{
        'overflow-hidden rounded-t-xl border-b border-gray-100 lg:rounded-t-2xl':
          !compare,
      }"
    >
      <Tag
        v-if="(item as ProductCatalog).bonusAccrual"
        primary
        class="absolute left-3 top-3 z-10"
      >
        {{
          t('scores', (product as ProductCatalog).bonusAccrual, {
            named: { n: n((product as ProductCatalog).bonusAccrual) },
          })
        }}
      </Tag>
      <NuxtLink
        :to="`/product/${item.id}/`"
        tabindex="-1"
        class="absolute inset-0 block h-full w-full"
        @click="$emit('goto')"
      >
        <ProductImage
          v-if="(item as ProductCatalog).imageS3 || item.coverPic"
          :items="(item as ProductCatalog).imageS3"
          :fallback-image-url="item.coverPic"
          :sizes="['medium']"
          :alt="item.name"
          :title="item.name"
          class="h-full w-full object-contain"
        />
        <ProductImageEmpty
          v-else
          class="text-blue-gray-300 mx-auto h-full w-full object-contain"
        />
      </NuxtLink>

      <div
        class="absolute left-1.5 top-1.5 flex flex-col items-start gap-1 lg:bottom-3 lg:left-3 lg:right-auto lg:top-auto"
      >
        <Tag v-if="item.isNovelty && item.quantity && item.price" red small>
          Новинка
        </Tag>
        <Tag v-if="item.isHit && item.quantity && item.price" red small>
          Хит
        </Tag>
        <Tag v-if="discountPercent" red small> Скидка </Tag>
        <Tag
          v-if="item.isNovelty && !(item.quantity && item.price)"
          primary
          small
        >
          Скоро в продаже
        </Tag>
        <Tag
          v-if="item.isMonoprotein"
          class="lg:bottom-3 lg:left-auto lg:right-3 lg:top-auto"
          violet
          small
        >
          Монопротеин
        </Tag>
      </div>
    </div>

    <div
      class="flex grow flex-col justify-end gap-1 bg-white p-3"
      :class="[compare ? 'mt-4 p-0' : 'rounded-b-xl lg:rounded-b-2xl']"
    >
      <div
        v-if="(item as ProductCatalog).code"
        class="text-secondary text-[12px]"
      >
        Артикул: {{ (item as ProductCatalog).code }}
      </div>

      <NuxtLink
        :to="`/product/${item.id}/`"
        class="justify-self-start"
        @click="$emit('goto')"
      >
        <div class="text-4 lg:text-heading-5 hyphens-auto font-medium">
          {{ item.name }}
        </div>
      </NuxtLink>

      <NuxtLink
        v-if="compare"
        :to="`/product/${item.id}/`"
        class="text-primary hover:text-primary-hover mt-2 text-sm underline underline-offset-2"
        @click="$emit('goto')"
      >
        Подробнее
      </NuxtLink>

      <div class="flex grow items-center">
        <Chip
          v-if="item.rating"
          class="h-[26px] space-x-1 bg-white lg:h-7"
          active-class="pl-0"
        >
          <Icon name="star" class="h-4 w-4 text-[#FFC709] lg:h-5 lg:w-5" />
          <span class="text-xs font-medium text-gray-900 lg:text-sm">{{
            n(item.rating)
          }}</span>
        </Chip>
        <NuxtLink :to="`/product/${item.id}/#reviews`" @click="emit('goto')">
          <Chip
            v-if="item.rating"
            class="h-[26px] space-x-1 bg-white lg:h-7"
            active-class="pl-0 pr-0 lg:pl-2"
          >
            <Icon name="comment" class="h-3 w-3 text-gray-300 lg:h-4 lg:w-4" />
            <span class="text-xs font-medium text-gray-900 lg:text-sm">{{
              t('reviews', (item as ProductCatalog).reviewsCount)
            }}</span>
          </Chip>
        </NuxtLink>
      </div>

      <div
        class="hidden min-h-6 items-center space-x-1.5 text-sm leading-6 lg:flex"
        :class="available.color"
      >
        <div class="h-1.5 w-1.5 rounded-full bg-current" />
        <div>{{ available.title }}</div>
      </div>

      <div class="mt-3 flex gap-3">
        <div
          v-if="userPrice"
          class="text-heading-4 lg:text-heading-3 font-semibold leading-7"
          :class="[
            `text-line-${props.line}-primary`,
            { 'text-primary': SITE_ID === 12 },
          ]"
        >
          <i18n-n :value="userPrice" format="currency">
            <template #fraction="slotProps">
              <span class="text-1">{{ slotProps.fraction }}</span>
            </template>
          </i18n-n>
        </div>
        <div
          v-if="discountPercent"
          class="hidden items-center space-x-1.5 font-medium leading-6 lg:flex"
        >
          <span
            class="text-secondary text-lg leading-6 line-through decoration-1"
          >
            {{ n(item.price ?? 0, 'currency') }}
          </span>
        </div>
        <div v-if="discountPercent" class="">
          <div
            class="text-danger bg-dark-red-50 hidden h-7 items-center rounded-lg px-2 text-xs font-semibold leading-6 lg:inline-flex"
          >
            -{{ n(discountPercent) }}%
          </div>
        </div>
      </div>

      <div class="mt-3">
        <ProductNotifyDialog
          v-if="!item.quantity && !item.isNovelty"
          :product="product"
          hide-product-navigate
        >
          <template #activator="{ buttonAttrs }">
            <Btn
              v-bind="buttonAttrs"
              :disabled="notifyLoading"
              primary
              lg
              :class="[
                'flex h-8 w-full justify-center whitespace-normal rounded-lg text-sm normal-case !leading-none lg:h-[52px] lg:rounded-xl lg:text-lg',
                isNotified
                  ? 'bg-green-900 hover:bg-green-700'
                  : SITE_ID === 7
                    ? 'text-general bg-[#F3F3F8] hover:bg-[#E5E5F0]'
                    : SITE_ID === 9
                      ? 'bg-primary-50 text-primary hover:bg-primary-hover'
                      : SITE_ID === 17
                        ? 'bg-bg_2 text-accent hover:bg-primary-hover hover:text-white'
                        : props.line
                          ? `bg-line-${props.line} hover:bg-line-${props.line}-hover`
                          : 'btn_notify bg-btn-secondary text-txt-accent hover:bg-primary-200',
              ]"
            >
              {{ isNotified ? 'Товар в избранном' : 'Сообщить о поступлении' }}
            </Btn>
          </template>
        </ProductNotifyDialog>
        <NuxtLink
          v-else-if="item.isNovelty && !item.quantity"
          :to="`/product/${item.id}/`"
          @click="emit('goto')"
        >
          <Btn
            :disabled="notifyLoading"
            primary
            lg
            :class="[
              'flex h-8 w-full justify-center whitespace-normal rounded-lg text-sm !leading-none lg:h-[52px] lg:rounded-xl lg:text-lg',
              isNotified
                ? 'bg-green-900 hover:bg-green-700'
                : SITE_ID === 7
                  ? 'text-general bg-[#F3F3F8] hover:bg-[#E5E5F0]'
                  : SITE_ID === 9
                    ? 'bg-primary-50 text-primary hover:bg-primary-hover'
                    : SITE_ID === 17
                      ? 'bg-bg text-primary hover:bg-primary-hover normal-case hover:text-white'
                      : SITE_ID === 21
                        ? 'bg-primary-50 text-primary hover:text-white'
                        : 'btn_notify bg-sapphire-700 hover:bg-sapphire-500',
            ]"
          >
            Подробнее
          </Btn>
        </NuxtLink>

        <div v-else-if="inCart > 0" class="flex h-8 items-center lg:h-[52px]">
          <div
            class="style_app flex w-full items-center justify-between rounded-xl bg-white p-0.5 ring-1 ring-gray-100"
          >
            <Btn
              :disabled="changeCartLoading"
              secondary
              class="style_app bg-primary-100 text-primary hover:text-primary-hover h-[28px] w-[28px] cursor-pointer rounded-lg p-0 lg:h-[42px] lg:w-[42px] lg:rounded-xl"
              @click="changeCart(inCart - 1)"
            >
              <span v-if="isMobile">-</span>
              <Icon v-else name="minus-solid" class="h-4 w-4" />
            </Btn>
            <div class="text-primary flex flex-col justify-center">
              <div class="text-4 md:text-heading-4 text-center">
                {{ inCart }}
              </div>
              <div
                v-if="available.amount < 10"
                class="leading-base text-danger text-xs"
              >
                Доступно {{ n(available.amount) }}&nbsp;шт.
              </div>
            </div>
            <Btn
              secondary
              :disabled="available.amount < 1 || changeCartLoading"
              class="style_app bg-primary-100 text-primary hover:text-primary-hover h-[28px] w-[28px] cursor-pointer rounded-lg p-0 lg:h-[42px] lg:w-[42px] lg:rounded-xl"
              @click="changeCart(inCart + 1)"
            >
              <span v-if="isMobile">+</span>
              <Icon v-else name="plus-solid" class="h-4 w-4" />
            </Btn>
          </div>
        </div>
        <Btn
          v-else
          lg
          :disabled="!available.amount || changeCartLoading"
          primary
          class="style_app h-8 w-full justify-center rounded-lg text-xs sm:text-sm lg:flex lg:h-[52px] lg:rounded-xl lg:text-base"
          :class="[
            `bg-line-${props.line} hover:bg-line-${props.line}-hover`,
            classAddToCart,
          ]"
          @click="changeCart(1)"
        >
          <span v-if="inCart < 1">{{
            isMobile ? 'В корзину' : 'Добавить в корзину'
          }}</span>
        </Btn>

        <slot
          name="actions"
          button-class="w-full justify-center rounded-lg lg:rounded-xl bg-primary-50 h-8 lg:h-[52px] text-sm lg:text-lg mt-2"
        />
      </div>
    </div>

    <div
      class="absolute right-1.5 top-1.5 flex-col space-y-1.5 lg:right-3 lg:top-3"
    >
      <Popper
        class="!block"
        hover
        :content="`${
          isFavorite
            ? 'Убрать&nbsp;из&nbsp;избранного'
            : 'Добавить&nbsp;в&nbsp;избранное'
        }`"
      >
        <Btn
          secondary
          :class="[
            'shadow-medium h-6 w-6 rounded-md bg-white p-0 transition-colors lg:h-8 lg:w-8 lg:rounded-lg',
            isFavorite
              ? 'hover:text-dark-red-900 bg-red-50 text-red-900'
              : 'text-secondary hover:text-primary',
          ]"
          :disabled="toggleFavoriteLoading"
          aria-label="Избранное"
          @click="toggleFavorite(item.id, () => emit('change:favorites'))"
        >
          <Icon
            name="favorite"
            :class="[
              'h-3 w-3 transition-colors lg:h-4 lg:w-4',
              { 'fill-current': isFavorite },
            ]"
          />
        </Btn>
      </Popper>

      <Popper
        v-if="!hideCompare"
        class="!block"
        hover
        :content="`${
          isCompare
            ? 'Убрать&nbsp;из&nbsp;сравнения'
            : 'Добавить&nbsp;в&nbsp;сравнение'
        }`"
      >
        <Btn
          secondary
          :class="[
            'shadow-medium h-6 w-6 rounded-md bg-white p-0 transition-colors lg:h-8 lg:w-8 lg:rounded-lg',
            isCompare
              ? 'hover:text-dark-red-900 bg-red-50 text-red-900'
              : 'text-secondary hover:text-primary',
          ]"
          :disabled="toggleCompareLoading"
          aria-label="Сравнение"
          @click="toggleCompare(item.id, () => emit('change:compare'))"
        >
          <Icon
            name="compare"
            :class="['h-3 w-3 lg:h-4 lg:w-4', { 'fill-current': isCompare }]"
          />
        </Btn>
      </Popper>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductCatalog, Product } from '@/openapi_fetch'
import { I18nN } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    item: ProductCatalog | Product
    compare?: boolean
    line?: string
    classAddToCart?: string
  }>(),
  {
    line: 'default',
  },
)

const emit = defineEmits(['change:favorites', 'change:compare', 'goto'])

const { isMobile } = useDevice()
const { SITE_ID } = useRuntimeConfig().public
const { t, n } = useI18n()

const product = toRef(props, 'item')
const {
  discountPercent,
  userPrice,
  available,
  inCart,
  changeCartLoading,
  isNotified,
  notifyLoading,
  toggleFavoriteLoading,
  isFavorite,
  toggleCompareLoading,
  isCompare,
  changeCart,
  toggleFavorite,
  toggleCompare,
} = useCatalogProduct(product)

const hideCompare = computed(() => SITE_ID === 13 || SITE_ID === 19)
const router = useRouter()
watchEffect(() => {
  const currentPath = router.currentRoute.value.path
  if (currentPath === `/product/${props.item.id}/`) {
    onProductPageOpen()
  }
})

async function onProductPageOpen() {
  if (router.currentRoute.value.hash) {
    await nextTick()
    const targetElement = document.querySelector(router.currentRoute.value.hash)
    if (targetElement) {
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }, 300)
    }
  }
}
</script>
